<script>
import { loadScript } from './load-script'
const scriptSrc = 'https://live.decidir.com/static/v2.5/decidir.js'

export default {
	props: {
		order: Object,
		creditCardForm: Object,
		data: Object,
	},
	methods: {
		resetDecidirIframe() {
			document.getElementById('cs-iframe-decidir')?.remove()

			const { orgId, merchantId, useCS, uid } = this.data
			const iframe = document.createElement('iframe')
			iframe.src = 'about:blank'
			iframe.id = 'cs-iframe-decidir'
			iframe.style.display = 'none'

			return new Promise((resolve, reject) => {
				iframe.addEventListener('load', function () {
					if (useCS) {
						const query = `org_id=${orgId}&session_id=${merchantId}${uid}`
						const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
						iframeDoc.body.innerHTML =
							`<script src="https://h.online-metrix.net/fp/tags.js?${query}" type="text/javascript">` +
							`</` +
							`script>`
						iframeDoc.body.innerHTML += `<noscript><iframe style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px" src="https://h.online-metrix.net/fp/tags?${query}"></iframe></noscript>`
						setTimeout(() => resolve(iframe), 4000)
					} else {
						resolve(iframe)
					}
				})
				iframe.addEventListener('error', function () {
					reject(new Error(`Error al cargar el iframe de Decidir`))
				})
				document.body.appendChild(iframe)
			})
		},
		async generateToken(iframe) {
			const _document = iframe.contentDocument || iframe.contentWindow.document
			const _window = iframe.contentWindow

			const { apiKey, sandbox, useCS, doorNumber, uid } = this.data
			await loadScript(scriptSrc, _document)

			_window.Decidir.prototype._createUniqueIdentifier = function () {
				return uid
			}

			const url = sandbox ? 'https://developers.decidir.com/api/v2' : 'https://live.decidir.com/api/v2'

			const decidir = new _window.Decidir(url, !useCS)
			decidir.setPublishableKey(apiKey)
			//decidir.setTimeout(5000)

			const inputs = {
				card_number: this.creditCardForm.number,
				security_code: this.creditCardForm.cvv,
				card_expiration_month: this.creditCardForm.expirationMonth,
				card_expiration_year: this.creditCardForm.expirationYear,
				card_holder_name: this.creditCardForm.payerName,
				card_holder_doc_type: 'dni',
				card_holder_doc_number: this.creditCardForm.payerIdNumber,
				card_holder_door_number: doorNumber,
				//device_unique_identifier: submitData.uid,
			}

			const formElm = _document.createElement('form')
			formElm.style.display = 'none'
			for (let [key, value] of Object.entries(inputs)) {
				let input = _document.createElement('input')
				input.setAttribute('value', value)
				input.setAttribute('type', 'text')
				input.setAttribute('name', key)
				input.setAttribute('data-decidir', key)
				formElm.appendChild(input)
			}

			const tokenResponse = await new Promise((resolve, reject) => {
				decidir.createToken(formElm, function (status, response) {
					formElm.remove()
					if (status != 200 && status != 201) {
						reject({ status, response })
					} else {
						resolve(response)
					}
				})
			})

			return tokenResponse
		},
		async postPayment(tokenResponse) {
			await this.$shopApi.post({
				url: `/credit-card-payment/decidir/${this.data.creditCardTransactionId}`,
				data: { token: tokenResponse },
				loading: false,
				onSuccess: ({ data }) => {
					if (data.approved) {
						this.$emit('approved')
					} else if (data.invalid) {
						this.$emit('invalid')
					} else {
						this.$emit('rejected', { rejectionCode: data.rejectionCode })
					}
				},
				onError: ({ message }) => {
					this.transactionFailed(message.description)
				},
			})
		},
		async transactionFailed(error) {
			await this.$shopApi
				.post({
					url: `/credit-card/transaction-failed`,
					loading: false,
					data: {
						transactionId: this.data.creditCardTransactionId,
						orderPaymentId: this.order.payments[0].id,
						error,
					},
				})
				.catch(() => null)

			this.$emit('rejected', {
				rejectionCode: 'PAYMENT_REQUEST_ERROR',
			})
		},
	},
	async mounted() {
		try {
			const iframe = await this.resetDecidirIframe()
			const tokenResponse = await this.generateToken(iframe)
			await this.postPayment(tokenResponse)
		} catch (err) {
			this.transactionFailed(err.stack || err.message)
		}
	},
	beforeDestroy() {
		document.getElementById('cs-iframe-decidir')?.remove()
	},
}
</script>

<template>
	<div></div>
</template>
